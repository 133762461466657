export const environment = {
  id: "roamstay",
  production: true,
  showAllMenus: false,
  dashboardUrl: 'https://app.roamstay.com',
  socketUrl: 'https://api.roamstay.com',
  apiUrl: 'https://api.roamstay.com/pms',
  mixpanelToken: 'e404dd83dc1f4dd39087a5c08ec81565',
  googleClientId: '879107131855-ajs4fsecjaoekdn2jpnbjqqbhqt2lro2.apps.googleusercontent.com',
  googleMapApiKey: "AIzaSyBvmLRshpYwiWI0j1czi7W6ZohQawUbmds",
};
